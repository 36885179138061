import React from "react";
import Profile from "../assets/profile.jpg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill, BsGlobe } from "react-icons/bs";
import { motion } from "framer-motion";

const Home = () => {

  const links = [
    {
      id: 1,
      child: (
        <>
          <FaLinkedin size={30} />
        </>
      ),
      href: "https://www.linkedin.com/in/swe-himelrana",
      style: "rounded-tr-md",
    },
    {
      id: 2,
      child: (
        <>
          <FaGithub size={30} />
        </>
      ),
      href: "https://github.com/swe-himelrana",
    },
    {
      id: 3,
      child: (
        <>
          <HiOutlineMail size={30} />
        </>
      ),
      href: "mailto:contact@himelrana.com",
    },
    {
      id: 4,
      child: (
        <>
          <BsFillPersonLinesFill size={30} />
        </>
      ),
      href: "/resume.pdf",
      style: "rounded-br-md",
      download: true,
    },
    {
      id: 5,
      child: (
        <>
          <BsGlobe size={30} />
        </>
      ),
      href: "https://swe.himelrana.com",
      style: "rounded-br-md",
    },
  ];
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800"
    >
      <Link to="/"></Link>
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <motion.h2 animate={{ x: 0 }} initial={{ x: -250 }} className="text-4xl sm:text-7xl font-bold text-white">
            I'm a Full Stack Developer
          </motion.h2>
          <motion.p initial={{ scale: 0 }} animate={{ scale: 1 }} className="text-gray-500 py-4 max-w-md">
            I have 4 years of experience building and desgining software.
            Currently, I love to work on web application using technologies like Django, Laravel,
            React, Vue, Bootstrap, Tailwind, Next JS and GraphQL.
          </motion.p>
          <motion.Link
            initial={{ x: 400 }}
            animate={{ x: 0 }}
            to="proficiency"
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            Proficiency
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </motion.Link>
          <div className="flex lg:hidden">

            <ul className="flex ml-6">

              {links.map(({ id, child, href, style, download }) => (
                <li
                  key={id}
                  className={
                    "flex  items-center w-auto h-14 px-4 hover:bg-gray-800" +
                    " " +
                    style
                  }
                >
                  <a
                    href={href}
                    className="flex justify-between items-center w-full text-white"
                    download={download}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {child}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div >
          <img
            src={Profile}
            alt="my profile"
            className="rounded-2xl mx-auto w-2/3 md:w-full -z-4"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
