import React from "react";

import RAB from "../assets/portfolio/experience/rab.png";
import FOA from "../assets/portfolio/experience/foa.png";
 

const Experience = () => {
  const techs = [
    {
      id: 1,
      src: RAB,
      title: "Rapid Action Battalion",
      jobtitle: "Ethical Hacker",
      duration: "March 2020 – December 2020",
      describe: "I have solved a lot of national cyber security issues.",
      style: "shadow-green-500",
    },
    {
      id: 2,
      src: FOA,
      title: "Face Of Art Technologies",
      jobtitle: "Software Engineer",
      duration: "January 2021 – March 2022",
      describe: "I have developed and maintained softwares, and also become Junior to Senior Developer",
      style: "shadow-orange-500",
    }
     
  ];

  return (
    <div
      name="experience"
      className="bg-gradient-to-b from-gray-800 to-black w-full h-screen"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
            Experience
          </p>
          <p className="py-6">These are the company I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          {techs.map(({ id, src, title, jobtitle,describe, duration, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <p className="mb-2 font-bold">{title}</p>
              <img src={src} alt="" className="w-20 mx-auto rounded-full" />
              <p className="mt-2 font-bold underline underline-offset-4">{jobtitle}</p>
              <p className="text-sm text-blue-400">{duration}</p>
              <p className="text-sm">{describe}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
