import React from "react";

import DIU from "../assets/portfolio/education/diu-c.png";
import Tongi from "../assets/portfolio/education/tongi.png" 

const Education = () => {
  const techs = [
    {
      id: 1,
      src: DIU,
      title: "Daffodil International University",
      jobtitle: "BSC in Software Engineering",
      duration: "January 2016 - December 2022",
      describe: "I have studied in Software Engineering and tried my best to understood all of the topics. Enjoyed programming contest and topics",
      style: "shadow-green-500",
    },
    {
      id: 2,
      src: Tongi,
      title: "Tongi Govt. College",
      jobtitle: "HSC in Science",
      duration: "January 2013 – March 2015",
      describe: "I have enjoyed studing science in this college and labratory of this college was rememberable. Enjoyed many Physics, Chemistry and Biology lab",
      style: "shadow-blue-500",
    }
     
  ];

  return (
    <div
      name="education"
      className="bg-gradient-to-b from-gray-800 to-black w-full h-screen"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
            Education
          </p>
          <p className="py-6">These are the University and College I've studied</p>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          {techs.map(({ id, src, title, jobtitle,describe, duration, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <p className="mb-2 font-bold">{title}</p>
              <img src={src} alt="" className="w-20 mx-auto rounded-full" />
              <p className="mt-2 font-bold underline underline-offset-4">{jobtitle}</p>
              <p className="text-sm text-blue-400 ">{duration}</p>
              <p className="text-sm p-2">{describe}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Education;
